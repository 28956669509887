import $ from 'jquery';

function scrollToTarget(targetId, offsetWhenOnTop, offsetWhenNotOnTop, selectors) {
    const targetElement = $('#' + targetId);
    const $mobileNavigation = $(selectors.navegation);
    if (targetElement.length) {
        $mobileNavigation.removeClass(selectors.navegationOpen);

        const scrollTopOffset = ($(window).scrollTop() > 0) ? offsetWhenOnTop : offsetWhenNotOnTop;

        $('html, body').animate({
            scrollTop: targetElement.offset().top - scrollTopOffset
        }, 800);
    }
}

function convertDate(dateRaw) {
    const date = new Date(dateRaw);
    const day = zeroFill(date.getUTCDate(), 2);
    const month = zeroFill(date.getUTCMonth() + 1, 2);
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
}

function zeroFill(number, length) {
    const fill = new Array(1 + length).join('0');
    return (fill + number).slice(-fill.length);
}

function epochToHumanDate(epoch) {
    const date = new Date(epoch);
    const formattedDate = date.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: '2-digit'});
    
    return formattedDate;
}


export {
    scrollToTarget,
    convertDate,
    zeroFill,
    epochToHumanDate
}