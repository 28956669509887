import $ from 'jquery';
import select2 from 'select2';

(function() {
    "use strict";

    var selectors = {
        self:      '.cmp-modal',
        modal: '.cmp-modal',
        btn: '.cmp-modal__open',
        close: '.cmp-modal--close',
        select: '.cmp-select-multiple',
        btnReset: '.cmp-modal--reset',
        form: "#modalForm",
        select: '.cmp-select-multiple',
        displayFlex: 'cmp-modal__dflex',
        displayNone: 'cmp-modal__dnone'
    };

    function Modal(config) {

        function init(config) {
            select2($);
            $(selectors.btn).on("click", function(){
                $(selectors.modal).removeClass(selectors.displayNone);
                $(selectors.modal).addClass(selectors.displayFlex);
            });

            $(selectors.close).on("click", function(){
                $(selectors.modal).removeClass(selectors.displayFlex);
                $(selectors.modal).addClass(selectors.displayNone);
            });
               
            $(selectors.self).find(selectors.select).select2();
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new Modal({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body             = document.querySelector("body");
        var observer         = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                // needed for IE
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new Modal({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }

}());
