import $ from 'jquery';
import slick from 'slick-carousel';

(function () {
    "use strict";

    var selectors = {
        self: '.cmp-tabelasexportam',
        cardContainer: ".cmp-tabelasexportam__tablist",
    };

    function initSlick($element) {
        var sliderOptions = {
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            variableWidth: false,
            dots: true,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        dots: true,
                        arrows: true,
                        variableWidth: true,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        dots: true,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 541,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                        arrows: false,
                        variableWidth: false,

                    }
                }
            ],
            prevArrow: '<button class="carousel-prev"></button>',
            nextArrow: '<button class="carousel-next"></button>',
        };
        $element.find(selectors.cardContainer).slick(sliderOptions);
    }

    function tabelasexportam(config) {
        function init(config) {
            config.element.removeAttribute('data-cmp-is');
            const $element = $(config.element);
            initSlick($element);
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);

        for (var i = 0; i < elements.length; i++) {
            new tabelasexportam({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            var nodesArray = [].slice.call(mutation.addedNodes);
            if (nodesArray.length > 0) {
                nodesArray.forEach(function (addedNode) {
                    if (addedNode.querySelectorAll) {
                    var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                    elementsArray.forEach(function (element) {
                        new tabelasexportam({ element: element });
                    });
                    }
                });
            }
        });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
}());