import $ from 'jquery';

(function() {
    "use strict";

    var selectors = {
        self:      '.cmp-programmingregion',
        dialogues: '.cmp-programmingregion__dialogues',
        round: '.cmp-programmingregion__round',
        link: '.cmp-programmingregion__schedule-link',
        linkDisabled: 'cmp-programmingregion__schedule-link-disabled'
    };

    function formattedDate(dateString) {
        if (dateString) {
            var dateParts = dateString.split('/'); 
            var year = parseInt(dateParts[2]); 
            var month = parseInt(dateParts[1]) - 1; 
            var day = parseInt(dateParts[0]);
            var newDate = new Date(year, month, day);
            return newDate;
        }
    }

    function checkRegistrations($element) {
        var startdatedialogues = formattedDate($element.find(selectors.dialogues).data("startdatedialogues"));
        var enddatedialogues = formattedDate($element.find(selectors.dialogues).data("enddatedialogues"));
        var startdateround = formattedDate($element.find(selectors.round).data("startdateround"));
        var enddateround = formattedDate($element.find(selectors.round).data("enddateround"));
        var currentDate = new Date();

        if (currentDate >= startdatedialogues && currentDate <= enddatedialogues) {
            $element.find(selectors.dialogues).find(selectors.link).text("Inscrições abertas");
            $element.find(selectors.dialogues).find(selectors.link).removeClass(selectors.linkDisabled);
        } else {
            $element.find(selectors.dialogues).find(selectors.link).text("Inscrições encerradas");
            $element.find(selectors.dialogues).find(selectors.link).addClass(selectors.linkDisabled);
        }

        if (currentDate >= startdateround && currentDate <= enddateround) {
            $element.find(selectors.round).find(selectors.link).text("Inscrições abertas");
            $element.find(selectors.round).find(selectors.link).removeClass(selectors.linkDisabled);
        } else {
            $element.find(selectors.round).find(selectors.link).text("Inscrições encerradas");
            $element.find(selectors.round).find(selectors.link).addClass(selectors.linkDisabled);
        }
    }

    function ProgrammingRegion(config) {

        function init(config) {
            const $element = $(config.element);
           
            checkRegistrations($element);

        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new ProgrammingRegion({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body             = document.querySelector("body");
        var observer         = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                // needed for IE
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new ProgrammingRegion({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }

}());
