import $ from "jquery";

(function () {
    "use strict";

    var selectors = {
        self: ".cmp-programmingmodal",
        cardList: ".cmp-programmingmodal__tabcontent",
        dateCard: '.cmp-programmingmodal__card-date',
        prevButtonPagination: ".cmp-programmingmodal__paginationbutton--previous",
        nextButtonPagination: ".cmp-programmingmodal__paginationbutton--next",
        pagination: ".cmp-programmingmodal__pagination-label",
        paginationButtonDisabled: "cmp-programmingmodal__paginationbutton--disabled",
        modalCard: '.cmp-modal__programmingmodal',
        closeModal: '.cmp-modal__programmingmodal-close',
        openModalCard: 'cmp-modal__programmingmodal-dflex',
    };

    function modalControl($element) {
        $element.find(selectors.cardList).on('click', function() {
            const id = $(this).data("id");
            $element.find(`.${id}`).addClass(selectors.openModalCard);
        });

        $element.find(selectors.closeModal).on('click',  function() {
            $element.find(selectors.modalCard).removeClass(selectors.openModalCard);
        });
    }

    function modalControlPagination($element) {
        $element.find(selectors.prevButtonPagination).on('click', function() {
            $element.find(selectors.modalCard).removeClass(selectors.openModalCard);
            const id = $(this).data("id") - 1;;
            $(`.${id}`).addClass(selectors.openModalCard);
        });

        $element.find(selectors.nextButtonPagination).on('click',  function() {
            $element.find(selectors.modalCard).removeClass(selectors.openModalCard);
            const id = $(this).data("id") + 1;
            $(`.${id}`).addClass(selectors.openModalCard);
        });
    }

    function formatFlowInfor($element) {
        $element.find(selectors.pagination).find(selectors.prevButtonPagination).each(function () {
            const date = $(this).data("date");
            const subtrairUm  = subtractDay(date);
            const formatdate  = formatdateNomeMes(subtrairUm);
            $element.find(selectors.pagination).find(selectors.prevButtonPagination).html( `<i  class="icon icon-arrow-left icon-arrow-blue"></i> ${formatdate}`);
        });

        $element.find(selectors.pagination).find(selectors.nextButtonPagination).each(function () {
            const date = $(this).data("date");
            const addUm  = addDay(date);
            const formatdate  = formatdateNomeMes(addUm);
            $element.find(selectors.pagination).find(selectors.nextButtonPagination).html( `${formatdate} <i  class="icon icon-arrow-right icon-arrow-blue"></i>`);
        });

        $element.find(selectors.cardList).find(selectors.dateCard).each(function () {
            const date = $(this).data("date");
            const formatdate  = formatdateNomeMes(date);
            $element.find(selectors.cardList).find(selectors.dateCard).html(formatdate);
        });

        $element.find(selectors.prevButtonPagination).each(function () {
            const id = $(this).data("id");

            if(id == 1) {
                $element.find(selectors.prevButtonPagination).addClass(selectors.paginationButtonDisabled);
            } else {
                $element.find(selectors.prevButtonPagination).removeClass(selectors.paginationButtonDisabled);
            }
        });

        $element.find(selectors.nextButtonPagination).each(function () {
            const id = $(this).data("id") + 1;
            const total = $(selectors.cardList).length;

            if(id > total) {
                $element.find(selectors.nextButtonPagination).addClass(selectors.paginationButtonDisabled);
            } else {
                $element.find(selectors.nextButtonPagination).removeClass(selectors.paginationButtonDisabled);
            }
        });
    }

    function subtractDay(data) {
        function padLeft(value) {
            return value < 10 ? '0' + value : '' + value;
        }

        var partesData = data.split('/');
        var dia = parseInt(partesData[0]);
        var mes = parseInt(partesData[1]);
        var ano = parseInt(partesData[2]);
      
        var novaData = new Date(ano, mes - 1, dia);
        novaData.setDate(novaData.getDate() - 1);
      
        var novoDia = novaData.getDate();
        var novoMes = novaData.getMonth() + 1;
        var novoAno = novaData.getFullYear();
      
        return padLeft(novoDia) + '/' + padLeft(novoMes) + '/' + novoAno;
    }

    function obterNomeMes(numeroMes) {
        var meses = [
          "Jan", "Feb", "Mar", "Apr", "May", "June",
          "July", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];
        return meses[numeroMes - 1];
    }

    function formatdateNomeMes(data) {
        var partesData = data.split('/');
        var dia = partesData[0];
        var mes = obterNomeMes(parseInt(partesData[1]));
        var ano = partesData[2];
        
        if (dia.slice(-1) == "1" && dia != "11") {
          dia += "st";
        } else if (dia.slice(-1) == "2" && dia != "12") {
          dia += "nd";
        } else if (dia.slice(-1) == "3" && dia != "13") {
          dia += "rd";
        } else {
          dia += "th";
        }
    
        return mes + ' ' + dia;
    }

    function addDay(data) {
        function padLeft(value) {
            return value < 10 ? '0' + value : '' + value;
        }

        var partesData = data.split('/');
        var dia = parseInt(partesData[0]);
        var mes = parseInt(partesData[1]);
        var ano = parseInt(partesData[2]);
      
        var novaData = new Date(ano, mes - 1, dia);
        novaData.setDate(novaData.getDate() + 1);
      
        var novoDia = novaData.getDate();
        var novoMes = novaData.getMonth() + 1;
        var novoAno = novaData.getFullYear();
      
        return padLeft(novoDia) + '/' + padLeft(novoMes) + '/' + novoAno;
    }

    function ProgrammingModal(config) {
        function init(config) {
            const $element = $(config.element);

            modalControl($element);
            formatFlowInfor($element);
            modalControlPagination($element);
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new ProgrammingModal({ element: elements[i] });
        }

        var MutationObserver =
            window.MutationObserver ||
            window.WebKitMutationObserver ||
            window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function (addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(
                                addedNode.querySelectorAll(selectors.self)
                            );
                            elementsArray.forEach(function (element) {
                                new ProgrammingModal({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true,
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
})();
