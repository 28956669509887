import $ from "jquery";
import contentSearchService from "../../site/services/contentSearchService";

(function () {
    "use strict";

    var selectors = {
        self: ".cmp-contentsearch-export",
        contentsList: ".cmp-contentsearch-export__list",
        contentsItems: ".cmp-contentsearch-export__items",
        newsItem: ".cmp-contentsearch-export__item",
        paginationList: ".cmp-contentsearch-export__pagination-list",
        prevButtonPagination: ".cmp-contentsearch-export__pagination-previous",
        nextButtonPagination: ".cmp-contentsearch-export__pagination-next",
        numbersPagination: ".cmp-contentsearch-export__pagination-numbers",
        paginationButton: ".cmp-contentsearch-export__pagination-go",
        inputSearch: ".cmp-contentsearch-export__input",
        searchForm: "#modalForm",
        searchFormBnt: "#modalFormBnt",
        closeModal : ".cmp-modal",
        btnReset: '.cmp-modal--reset',
        select: '.cmp-select-multiple',
        modal: ".cmp-modal",
        modalExport: ".cmp-modal__export",
        modalBody: ".cmp-modal__export-render",
        cardExport: ".cmp-content-export__card",
        cardExportImage: ".cmp-content-export__card-image",
        openModalExport: "cmp-modal__dflex",
        closeModalExport: ".cmp-modal__export-close",
        inputCity: '.cmp-form-input[name=city]',
        inputState: '.cmp-select-state',
        inputCategory: '.cmp-select-category',
        loading: 'loading'
    };

    var $element = null;
    var RESOUCE_PATH = null;
    var SELECTOR = null;
    var perPage = null;
    var state = {};
    var offset = 0;
    var currentPage = 1;
    var fulltextval = "";
    var timer;
    var city = "";
    var category = "";

    const contentsList = {
        update() {
            var page = currentPage - 1;
            offset = page * perPage;
            getContent();
        }
    }

    const controls = {
        next() {
            currentPage++;
            if (currentPage >= state.totalPage) {
                currentPage = state.totalPage;
            }
        },
        prev() {
            currentPage--;
            if (currentPage < 1) {
                currentPage = 1;
            }
        },
        goTo(page) {
            currentPage = page;

            if (page < 1) {
                currentPage = 1;
            }

            currentPage = +page;

            if (page > state.totalPage) {
                currentPage = state.totalPage;
            }
        }
    }

    const buttons = {
        create(number) {
            $element.find(selectors.numbersPagination).append(
                `<button class="cmp-contentsearch-export__pagination-button cmp-contentsearch-export__pagination-go ${currentPage == number ? "cmp-contentsearch-export__pagination-active" : ""}">${number}</button>`
            );
        },

        update() {
            const { maxLeft, maxRight } = buttons.calculateMaxVisible()
            $element.find(selectors.numbersPagination).html('');
            for (let page = maxLeft; page <= maxRight; page++) {
                buttons.create(page);
            }

        },

        calculateMaxVisible() {
            const { maxVisibleButtons } = state;

            let maxLeft = (currentPage - Math.floor(maxVisibleButtons / 2));
            let maxRight = (currentPage + Math.floor(maxVisibleButtons / 2));

            if (maxLeft < 1) {
                maxLeft = 1;
                maxRight = maxVisibleButtons;
            }

            if (maxRight > state.totalPage) {
                maxLeft = state.totalPage - (maxVisibleButtons - 1);
                maxRight = state.totalPage;

                if (maxLeft < 1) maxLeft = 1;
            }

            return { maxLeft, maxRight };
        }
    }

    function search() {
        $element.find(selectors.inputSearch).on('keyup', function (e) {
            clearTimeout(timer);
            fulltextval = $(this).val();
            timer = setTimeout(function () {
                offset = 0;
                currentPage = 1;
                getContent();
            }, 800);
        });
    }

    function update() {
        contentsList.update();
        buttons.update();
    }

    function nextPagination() {
        $element.find(selectors.nextButtonPagination).on('click', function () {
            if (currentPage < state.totalPage) {
                controls.next();
                update();
            }
        });
    }

    function prevPagination() {
        $element.find(selectors.prevButtonPagination).on('click', function () {
            if (currentPage > 1) {
                controls.prev();
                update();
            }
        });
    }

    function createPagination(totalItems) {
        state = {
            limit: perPage,
            totalPage: Math.ceil(totalItems / perPage),
            maxVisibleButtons: 5
        };

        buttons.update();
    }

    function goToPagination() {
        $element.find(selectors.numbersPagination).on('click', selectors.paginationButton, function () {
            controls.goTo($(this).html());
            update();
        });
    }

    function mountCategory(category) {
        var array = [];
        $(category).each(function(key, item) {
            array.push(item.title)
        });

        return `<p> ${array} <p>`
    }

    function templateContacts(contacts) {
        if(contacts.name != null || contacts.email != null ||contacts.phone != null ||contacts.pavilion != null ||contacts.stand != null) {
            return `
                <div class="cmp-content-export__card-category">
                    <p class="cmp-content-export__card-link"><strong>Contacts</strong></p>
                    ${contacts.name !== null ? `<p class="cmp-content-export__card-text"><strong>Name:</strong> ${contacts.name}</p>` : ''}
                    ${contacts.email !== null ? `<p class="cmp-content-export__card-text"><strong>Mail:</strong> ${contacts.email}</p>` : ''}
                    ${contacts.phone !== null ? `<p class="cmp-content-export__card-text"><strong>Phone:</strong> ${contacts.phone}</p>` : ''}
                    ${contacts.pavilion !== null ? `<p class="cmp-content-export__card-text"><strong>Pavilion:</strong> ${contacts.pavilion}</p>` : ''}
                    ${contacts.stand !== null ? `<p class="cmp-content-export__card-text"><strong>Stand:</strong> ${contacts.stand}</p>` : ''}
                </div>
            `;
        }

        return ``;
    }

    function templateModal(title, city, state, category, description, download, image, link, contacts) {
        return `
            <div class="cmp-modal__export-body cmp-content-export__card-modal aem-Grid aem-Grid--12">
                <div class="aem-GridColumn aem-GridColumn--phone--12 aem-GridColumn--tablet--3 aem-GridColumn--default--4">
                    <div class="cmp-content-export__card cmp-content-export__card-modal--card">
                        <img class="cmp-content-export__card-image" src="${image}">
                        <div class="cmp-content-export__card-content">
                            <h1 class="cmp-content-export__card-title">${title}</h1>
                            <a class="cmp-content-export__card-link" href="${link}">${link}</a>
                            <p class="cmp-content-export__card-text" >${city} - ${state}</p>
                            <div class="cmp-content-export__card-category">
                                <p class="cmp-content-export__card-link"><strong>Category</strong></p>
                                <p class="cmp-content-export__card-text">${category}</p>
                            </div>
                            ${templateContacts(contacts)}                           
                        </div>
                    </div>
                </div>
                <div class="aem-GridColumn aem-GridColumn--phone--12 aem-GridColumn--tablet--9 aem-GridColumn--default--8 cmp-content-export__card-modal--content">
                    <p class="cmp-content-export__card-text">
                        ${description}
                    </p>
                </div>
            </div>
        `;
    }

    function cardExport(item) {
        return `
            <img class="cmp-content-export__card-image" src="${item.thumbnail}">
            <div class="cmp-content-export__card-content">
                <h1 class="cmp-content-export__card-title">${item.title}</h1>
                <a class="cmp-content-export__card-link" href="${item.website}">${item.website}</a>
                <p class="cmp-content-export__card-text">${item.city} - ${item.state[0].title}</p>
                <div class="cmp-content-export__card-category">
                    <p class="cmp-content-export__card-link"><strong>Category</strong></p>
                    <p class="cmp-content-export__card-text" >
                        ${mountCategory(item.categories)}
                    </p>
                </div>
            </div>
        `;
    }

    function template(item){
        return `
            <li class="cmp-contentsearch-export__list-item">
                <div class="cmp-content-export__card" 
                    data-title="${item.title}"
                    data-link="${item.website}" 
                    data-city="${item.city}"
                    data-state="${item.state[0].title}"
                    data-category="${mountCategory(item.categories)}"
                    data-description="${item.description[0]}"
                    data-download="${item.file}"
                    data-image="${item.thumbnail}"
                    ${item.contactName !== "" ? `data-contactName="${item.contactName}"` : ''}
                    ${item.contactPhone !== "" ? `data-contactPhone="${item.contactPhone}"` : ''}
                    ${item.contactEmail !== "" ? `data-contactEmail="${item.contactEmail}"` : ''}
                    ${item.pavilion !== "" ? `data-pavilion="${item.pavilion}"` : ''}
                    ${item.stand !== "" ? `data-stand="${item.stand}"` : ''}
                >
                    ${cardExport(item)}
                </div>
            </li>
        `;
    }

    function mountContent(data) {
        $element.find(selectors.contentsList).html("");

        $(data).each(function (key, item) {
            $element.find(selectors.contentsList).append(template(item));
        });
    }

    function getContent() {
        const parameters = {
            offset: offset,
            limit: perPage,
            city: city,
            tags: category,
            fulltext: fulltextval
        }

        $element.find(selectors.contentsList).html("");
        $element.addClass(selectors.loading);

        contentSearchService.getContentSearchDesignerExport(RESOUCE_PATH, SELECTOR, parameters).then(function(request) {
            if(request.data) {
                createPagination(request.total);
                mountContent(request.data, SELECTOR);
                $element.removeClass(selectors.loading);
            }
        });
    }

    function modalControl() {
        $element.find(selectors.contentsList).on('click', selectors.cardExportImage, function() {
            const modal = $element.find(selectors.modalExport);
            const data = $(this).parent();

            var title = data.data('title');
            var city = data.data('city');
            var state = data.data('state');
            var category = data.data('category');
            var description = data.data('description');
            var download = data.data('download');
            var image = data.data('image');
            var link = data.data('link');

            const contacts = {
                name: data.data('contactname') ?? null,
                email:  data.data('contactemail') ?? null,
                phone: data.data('contactphone') ?? null,
                pavilion: data.data('pavilion') ?? null,
                stand: data.data('stand') ?? null
            }

            modal.find(selectors.modalBody).html("")
            modal.find(selectors.modalBody).append(templateModal(title, city, state, category, description, download, image, link, contacts));
            modal.addClass(selectors.openModalExport);

        });

        $element.find(selectors.closeModalExport).on('click',  function() {
            $element.find(selectors.modalExport).removeClass(selectors.openModalExport);
        });
    }

    function searchModal() {
        $(selectors.modal).find(selectors.searchFormBnt).on('click', function(e) {
            e.preventDefault();

            var modal = $(selectors.modal);
            var getstate = modal.find(selectors.inputState).val();
            var getCategory = modal.find(selectors.inputCategory).val();
            var tagsFilter = getstate.concat(getCategory);
            city = modal.find(selectors.inputCity).val();
            category = tagsFilter;

            getContent();
            modal.removeClass(selectors.openModalExport);
        });
    }

    function resetModal() {
        $(selectors.modal).find(selectors.btnReset).on('click', function() {
            city = "";
            category = "";

            var modal = $(selectors.modal);
            modal.find(selectors.inputCity).val("");
            modal.find(selectors.inputState).val("").trigger('change');
            modal.removeClass(selectors.openModalExport);

            getContent();
        });
    }

    function contentSearch(config) {
        function init(config) {
            $element = $(config.element);
            RESOUCE_PATH = $element.data('path');
            SELECTOR = $element.data('selector');
            perPage = $element.data('results');
            
            getContent();
            prevPagination();
            nextPagination();
            goToPagination();
            search();
            modalControl();
            searchModal();
            resetModal();
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new contentSearch({ element: elements[i] });
        }

        var MutationObserver =
            window.MutationObserver ||
            window.WebKitMutationObserver ||
            window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function (addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(
                                addedNode.querySelectorAll(selectors.self)
                            );
                            elementsArray.forEach(function (element) {
                                new contentSearch({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true,
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
})();