import $ from 'jquery';

(function() {
    "use strict";

    var selectors = {
        self: '#cmp-header__wsl',
        openMenuBtn: '#cmp-button__wslheader',
        openMenuContainer: '#cmp-header__wslnavegation',
        openMenuClass: 'cmp-header__height100vh',
				closeMenuBtn: '#cmp-button__wslclosebtn'
    };

    function WslHeader(config) {

        function init(config) {
        	const $element = $(selectors.self);
					const $openBtn = $element.find(selectors.openMenuBtn);
					const $closeBtn = $element.find(selectors.closeMenuBtn);
          $openBtn.on('click', function(event) {
            $element.find(selectors.openMenuContainer).addClass(selectors.openMenuClass);
          });
					$closeBtn.on('click', function(event) {
            $element.find(selectors.openMenuContainer).removeClass(selectors.openMenuClass);
          });
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new WslHeader({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body             = document.querySelector("body");
        var observer         = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                // needed for IE
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new WslHeader({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }

}());
