import $ from "jquery";
import httpServiceRequest from "../../site/services/httpServiceRequest";

(function () {
    "use strict";

    var selectors = {
        self: ".cmp-wslsearch",

        contentsList: ".cmp-wslsearch__list",
        prevButtonPagination: ".cmp-wslsearch__paginationbutton--previous",
        nextButtonPagination: ".cmp-wslsearch__paginationbutton--next",
        numbersPagination: ".cmp-wslsearch__paginationlist--numbers",
        buttonPagination: ".cmp-wslsearch__paginationbutton",
        pagination: ".cmp-wslsearch__pagination",
        paginationDisabled: "cmp-wslsearch__pagination--disabled",
        inputOder: ".cmp-select__order",
        buttonFilter: ".cmp-wslsearch__filterbutton",
        buttonFilterActive: "cmp-wslsearch__filterbutton--active",
        resetButton: ".cmp-wslsearch__clean",
        loadingCmp: ".cmp-wslsearch__loading",
        loadingCmpActive: "cmp-wslsearch__loading--active",
        bottonSearch: ".cmp-wslsearch__search--button",
        modalFilter: ".cmp-modal-wslsearchfilter",
        openModalFilter: "cmp-modal__dflex",
        modalFilterClose: ".cmp-modal-wslsearchfilter-close",
        modalBody: ".cmp-modal__wslsearch-render",
        cardWslsearch: ".cmp-wslsearch__card",
        modalWslsearch: ".cmp-modal__wslsearch",
        openModalWslsearch: "cmp-modal__dflex",
        closeModalWslsearch: ".cmp-modal__wslsearch-close",
        accordionitem: ".cmp-modal-wslsearchfilter-accordionitem",
        accordionitemactive: "cmp-modal-wslsearchfilter-checkbox--active",
        labelIcon: ".cmp-modal-wslsearchfilter-icon",
        labelIconactive: "cmp-modal-wslsearchfilter-icon--active",
        searchFormBnt: '.cmp-modal-wslsearchfilter-button',
        inputFULLTEXT: '.cmp-modal-wslsearchfilter-input[name=fulltext]',
        inputCategory: '.cmp-modal-wslsearchfilter-checkbox-input[name=categories]',
        checkboxInput: '.cmp-modal-wslsearchfilter-checkbox-input'
    };

    var textSelectors = {
        searchFor: "#searchFor",
    };

    const contentSearchCMP = $(selectors.self);
    var OFFSET = 0;
    var currentPage = 1;
    var state = {};

    var TOTAL_RESULT = 0;
    var DATA_CONTENT = [];

    var fulltextval = "";
    let CATEGORY = [];
    var tagFiltro = "";

    const contentsList = {
        update() {
            OFFSET = (currentPage - 1) * $(selectors.self).data("perpage");
        },
    };

    const controls = {
        next() {
            currentPage++;
            if (currentPage >= state.totalPage) {
                currentPage = state.totalPage;
            }
        },
        prev() {
            currentPage--;
            if (currentPage < 1) {
                currentPage = 1;
            }
        },
        goTo(page) {
            currentPage = page;

            if (page < 1) {
                currentPage = 1;
            }

            currentPage = +page;

            if (page > state.totalPage) {
                currentPage = state.totalPage;
            }
        },
    };

    const buttons = {
        create(number) {
            $(selectors.numbersPagination).append(
                `<button class="cmp-wslsearch__paginationbutton ${
                    currentPage == number
                        ? "cmp-wslsearch__paginationbutton--active"
                        : ""
                }">${number}</button>`
            );
        },

        update() {
            const { maxLeft, maxRight } = buttons.calculateMaxVisible();

            $(selectors.numbersPagination).html("");
            for (let page = maxLeft; page <= maxRight; page++) {
                buttons.create(page);
            }
        },

        calculateMaxVisible() {
            const { maxVisibleButtons } = state;

            let maxLeft = currentPage - Math.floor(maxVisibleButtons / 2);
            let maxRight = currentPage + Math.floor(maxVisibleButtons / 2);

            if (maxLeft < 1) {
                maxLeft = 1;
                maxRight = maxVisibleButtons;
            }

            if (maxRight > state.totalPage) {
                maxLeft = state.totalPage - (maxVisibleButtons - 1);
                maxRight = state.totalPage;

                if (maxLeft < 1) maxLeft = 1;
            }

            return { maxLeft, maxRight };
        },
    };

    function loadContent($element) {
        $(DATA_CONTENT).each(function () {
            $element.find(selectors.contentsList).append(template(this));
        });
    }

    function template(item) {
        return `<li class="cmp-wslsearch__card"
                data-title="${item.title}"
                data-image="${item.logo}"
                data-description="${JSON.stringify(item.description).replace(/"/g, '&quot;')}"
                data-category='${JSON.stringify(item.startupsService)}'
                data-link="${item.website}" 
                data-location="${item.location}" 
                data-programming="${item.publicationDate}" 
                data-namecontact="${item.contactName}"
                data-email="${item.contactEmail}"
                data-instagram="${item.instagram}"
                data-linkedin="${item.linkedin}"
                data-facebook="${item.facebook}"
                data-twitter="${item.x}"
                data-youtube="${item.youtube}">
                    ${item.logo !== "" ? `<img class="cmp-wslsearch__card-image" src="${item.logo}">` : '<div class="cmp-wslsearch__card-image"></div>'}
                    <h4 class="cmp-wslsearch__card-title">${item.title}</h4>
            </li>`;
    }

    function createPaginationControl($element) {
        state = {
            limit: $element.data("perpage"),
            totalPage: Math.ceil(TOTAL_RESULT / $element.data("perpage")),
            maxVisibleButtons: 5,
        };

        buttons.update();
    }

    function update($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH) {
        contentsList.update();
        searchFlow($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
        buttons.update();
    }

    function mountUrl(LIMIT_PAGE, DAM_PATH) {
        return `${DAM_PATH ? `dampath=${DAM_PATH}&`: ""}offset=${OFFSET}&limit=${LIMIT_PAGE}${tagFiltro !== "" ? `&tags=${tagFiltro}` : ''}&fulltext=${fulltextval}${CATEGORY !== "" ? `&tags=${CATEGORY.join('&tags=')}` : ''}`;
    }

    async function getResults($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH) {
        const SELECTOR = 'searchwsl';
        const baseurl = `${RESOURCE_PATH}.${SELECTOR}.json?${mountUrl(LIMIT_PAGE, DAM_PATH)}`;

        return httpServiceRequest.get(baseurl).then((data) => {
            if(data.data) {
                TOTAL_RESULT = data.total;
                DATA_CONTENT = data.data;
            }
        });
    }

    function setTexts() {
        const paginationElement = contentSearchCMP.find(selectors.pagination);
        const searchForElement = contentSearchCMP.find(textSelectors.searchFor);
        searchForElement.html(TOTAL_RESULT + ' ');

        if (TOTAL_RESULT == 0 || !TOTAL_RESULT) {
            paginationElement.addClass(selectors.paginationDisabled);
            searchForElement.addClass(selectors.paginationDisabled);
        }
        if (paginationElement.hasClass(selectors.paginationDisabled) &&
            searchForElement.hasClass(selectors.paginationDisabled)
        ) {
            paginationElement.removeClass(selectors.paginationDisabled);
            searchForElement.removeClass(selectors.paginationDisabled);
        }
    }

    function searchFlow($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH) {
       $element.find(selectors.contentsList).html("");
       $element.find(selectors.loadingCmp).addClass(selectors.loadingCmpActive);

        getResults($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH)
            .then(() => {
                loadContent($element);
                $element.find(selectors.loadingCmp).removeClass(selectors.loadingCmpActive);
                $(selectors.searchFormBnt).html('Show results  ' + '(' +  TOTAL_RESULT + ')');
            })
            .then(() => {
                setTexts();
            })
            .then(() => {
                createPaginationControl($element);
            });
    }

    function searchFlowModal($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH) {
        getResults($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH)
            .then(() => {
                $(selectors.searchFormBnt).html('Show results  ' + '(' +  TOTAL_RESULT + ')');
            })
    }

    function setListenerFilter($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH){
        var modal = $(selectors.modalFilter);

        modal.find(selectors.checkboxInput).on('change', () => {
            getAllFilters($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
        });

        modal.find(selectors.inputFULLTEXT).on('keyup', () => {
            getAllFilters($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
        });
    }
    
    function getAllFilters($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH){
        CATEGORY = [];

        var modal = $(selectors.modalFilter);

        modal.find(selectors.inputCategory).filter(':checked').each(function () {
            CATEGORY.push($(this).val());
        });

        fulltextval = modal.find(selectors.inputFULLTEXT).val();

        searchFlowModal($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
    }

    function searchModal($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH) {
        $(selectors.modalFilter).find(selectors.searchFormBnt).on('click', function(e) {
            e.preventDefault();

            CATEGORY = [];
    
            var modal = $(selectors.modalFilter);
            modal.find(selectors.inputCategory).filter(':checked').each(function () {
                CATEGORY.push($(this).val());
            });
            fulltextval = modal.find(selectors.inputFULLTEXT).val();
           
            searchFlow($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
            modal.removeClass(selectors.openModalFilter);
        });
    }

    function buttonFilterTag($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH) {
        $element.find(selectors.buttonFilter).on("click", function () {

            $element.find(selectors.buttonFilter).removeClass(selectors.buttonFilterActive);
            $(this).addClass(selectors.buttonFilterActive);

            const dataValue = $(this).data("value");
            tagFiltro = dataValue != "All" ? dataValue : "";

            searchFlow($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
        });
    }

    function resetPaginationControl($element) {
        OFFSET = 0;
        currentPage = 1;
        contentsList.update();
    }

    function clickReset($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH) {
        $element.find(selectors.resetButton).on("click", function () {

            fulltextval = "";
            CATEGORY = [];
            tagFiltro = "";

            var modal = $(selectors.modalFilter);
            modal.find(selectors.inputFULLTEXT).val("");
            modal.find('input[type="checkbox"]').prop('checked', false);

            $element.find(selectors.buttonFilter).removeClass(selectors.buttonFilterActive);
            $element.find(selectors.buttonFilter).each(function () {

                const dataValue = $(this).data("value");
                if(dataValue == "All"){
                    $(this).addClass(selectors.buttonFilterActive);
                }
            });
                       
            resetPaginationControl($element);
            searchFlow($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
        });
    }

    function templateModal(title, image, description, category, link, location, programming, namecontact, email
        , instagram, facebook, youtube, linkedin, twitter) {
        const shouldTextTruncate = $(selectors.self).data("limittext");
        const hasSocialMedia = instagram || facebook || youtube || linkedin || twitter;
        const hasContactInfo = namecontact || email;
        return `
            <div class="cmp-modal__wslsearch-card">
                ${image !== "" ? `<img class="cmp-modal__wslsearch-card-image" src="${image}">` : ''}
                
                <div class="cmp-modal__wslsearch-card-content">
                    <h1 class="cmp-modal__wslsearch-card-title">${title}</h1>

                    <p class="cmp-modal__wslsearch-card-text" >${location}</p>
                    ${programming !== null ? `<p class="cmp-modal__wslsearch-card-text" >${convertDate(programming)}</p>` : ''}           

                    <a class="cmp-modal__wslsearch-card-link" target="_blank" href="${link}">${link}</a>

                    <div class="cmp-modal__wslsearch-card-listcategory">
                        <div class="cmp-modal__wslsearch-card-category">
                            ${mountTag(category)}
                        </div>
                    </div>

                    <div class="cmp-modal__wslsearch-card-description">
                        ${shouldTextTruncate ? limitText(description) : description}
                    </div>
                </div>

                ${hasSocialMedia ? `
                    <div class="cmp-modal__wslsearch-footer">
                        <h1 class="cmp-modal__wslsearch-footer-subtitle">Social media:</h1>
                        <div class="cmp-modal__wslsearch__rede__social">
                            ${instagram !== "" ? `<a href="${instagram}" class="cmp-modal__wslsearch__rede__social_instagram" target="_blank"></a>` : ''}
                            ${facebook !== "" ? `<a href="${facebook}" class="cmp-modal__wslsearch__rede__social_facebook" target="_blank"></a>` : ''}
                            ${youtube !== "" ? `<a href="${youtube}" class="cmp-modal__wslsearch__rede__social_youtube" target="_blank"></a>` : ''}
                            ${linkedin !== "" ? `<a href="${linkedin}" class="cmp-modal__wslsearch__rede__social_linkedin" target="_blank"></a>` : ''}
                            ${twitter !== "" ? `<a href="${twitter}" class="cmp-modal__wslsearch__rede__social_twitter" target="_blank"></a>` : ''}             
                        </div>
                    </div>` : ''}

                ${hasContactInfo ? `
                    <div class="cmp-modal__wslsearch-footer">
                        <h1 class="cmp-modal__wslsearch-footer-subtitle">Contact info:</h1>
                        ${namecontact !== "" ? `<p class="cmp-modal__wslsearch-footer-name" >${namecontact}</p>` : ''}
                        ${email !== "" ? `<p class="cmp-modal__wslsearch-footer-email" >${email}</p>` : ''}                    
                    </div>` : ''}
            </div>
        `;
    }

    function mountTag(data) {
        var result = '';
        for (var i = 0; i < data.length; i++) {
            const title = data[i].title || formatTextTag(data[i].id.split('/').pop());
            result += `<div class="cmp-modal__wslsearch-card-tag">${title}</div>`;
        }
        return result.slice(0, -1);
    }

    function formatTextTag(text) {
        return text.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }

    function limitText(item) {
        if (!item) return "";
        return item.length >  450 ? item.substring(0, 450).concat("...") : item;
    }

    function obterNomeMes(numeroMes) {
        var meses = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        return meses[numeroMes - 1];
    }

    function convertDate(dateRaw) {
        const date = new Date(dateRaw);
        const day = zeroFill(date.getUTCDate(), 2);
        const month = zeroFill(date.getUTCMonth() + 1, 2);
        const year = date.getUTCFullYear();
        const formatdate = `${day}/${month}/${year}`;

        return formatdateNomeMes(formatdate);
    }

    function zeroFill(number, length) {
        const fill = new Array(1 + length).join('0');
        return (fill + number).slice(-fill.length);
    }
    
    function formatdateNomeMes(data) {
        var partesData = data.split('/');
        var dia = partesData[0];
        var mes = obterNomeMes(parseInt(partesData[1]));
        var ano = partesData[2];
        
        if (dia.slice(-1) == "1" && dia != "11") {
          dia += "st";
        } else if (dia.slice(-1) == "2" && dia != "12") {
          dia += "nd";
        } else if (dia.slice(-1) == "3" && dia != "13") {
          dia += "rd";
        } else {
          dia += "th";
        }
    
        return mes + ' ' + dia;
    }

    function modalControl($element) {
        $element.find(selectors.contentsList).on('click', selectors.cardWslsearch, function() {
            const modal = $element.find(selectors.modalWslsearch);

            var title = $(this).data('title');
            var image = $(this).data('image');
            var description = $(this).data('description');
            var category = $(this).data('category');
            var link = $(this).data('link');
            var location = $(this).data('location');
            var programming = $(this).data('programming');
            var namecontact = $(this).data('namecontact');
            var email = $(this).data('email');
            var instagram = $(this).data('instagram');
            var facebook = $(this).data('facebook');
            var youtube = $(this).data('youtube');
            var linkedin = $(this).data('linkedin');
            var twitter = $(this).data('twitter');

            if (Array.isArray(description)) {
                var descriptionData = description.map(function(item) {
                  return item.replace(/\"/g, '"');
                });
            }

            modal.find(selectors.modalBody).html("")
            modal.find(selectors.modalBody).append(templateModal(title, image, descriptionData, category, link, location, programming, namecontact, email, instagram, facebook, youtube, linkedin, twitter));

            modal.addClass(selectors.openModalWslsearch);

        });

        $element.find(selectors.closeModalWslsearch).on('click',  function() {
            $element.find(selectors.modalWslsearch).removeClass(selectors.openModalWslsearch);
        });
    }

    function modalControlFilter($element) {
        $element.find(selectors.bottonSearch).on('click', function() {
            const modal = $(selectors.modalFilter);
            modal.addClass(selectors.openModalFilter);

        });

        $(selectors.modalFilterClose).on('click',  function() {
            $(selectors.modalFilter).removeClass(selectors.openModalFilter);
        });
    }

    function modalAccordionItem($element) {
        $(selectors.accordionitem).on('click', function() {
            const accordion = $(this).data('accordion');

            if($(`.${accordion}`).hasClass(selectors.accordionitemactive) ){
                $(`.${accordion}`).removeClass(selectors.accordionitemactive);
                $(this).find(selectors.labelIcon).removeClass(selectors.labelIconactive);
            } else {
                $(`.${accordion}`).addClass(selectors.accordionitemactive);
                $(this).find(selectors.labelIcon).addClass(selectors.labelIconactive);
            }
        });
    }

    function WslSearch(config) {
        function init(config) {
            const $element = $(config.element);

            var LIMIT_PAGE = $(selectors.self).data("perpage");
            var RESOURCE_PATH = $(selectors.self).data("path");
            var DAM_PATH = $(selectors.self).data("dam");
            searchFlow($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
            clickReset($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
            buttonFilterTag($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
            modalControl($element);
            modalControlFilter($element);
            modalAccordionItem($element);
            searchModal($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
            setListenerFilter($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);

            $(config.element)
                .find(selectors.nextButtonPagination)
                .click(function () {
                    if (currentPage < state.totalPage) {
                        controls.next();
                        update($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
                    }
                });

            $(config.element)
                .find(selectors.prevButtonPagination)
                .click(function () {
                    if (currentPage > 1) {
                        controls.prev();
                        update($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
                    }
                });

            $(config.element)
                .find(selectors.numbersPagination)
                .on("click", selectors.buttonPagination, function () {
                    controls.goTo($(this).html());
                    update($element, LIMIT_PAGE, RESOURCE_PATH, DAM_PATH);
                });
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new WslSearch({ element: elements[i] });
        }

        var MutationObserver =
            window.MutationObserver ||
            window.WebKitMutationObserver ||
            window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function (addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(
                                addedNode.querySelectorAll(selectors.self)
                            );
                            elementsArray.forEach(function (element) {
                                new WslSearch({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true,
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
})();
