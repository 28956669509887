import $ from 'jquery';

(function () {
    "use strict";

    var selectors = {
        self: '#cmp-container__exportamaisfaq',
        button: '.cmp-container__topfaqcontainer',
        faqContainer: '.cmp-container__faqexportamaiscontainer',
        bottomContainer: '.cmp-container__bottomfaqcontainer',
        bottomContainerOpen: 'cmp-container__bottomfaqcontaineropen',
        cmpButton: '.cmp-button',
        rotateButton: 'cmp-button__exportamaisrotate180'
    };

    function FAQContainer(config) {
        function init(config) {
            var $element = $(config.element);

            $element.find(selectors.button).on('click', function () {
                var parentDiv = $(this).closest(selectors.faqContainer);
                var childDiv = parentDiv.find(selectors.bottomContainer);
                var button = parentDiv.find(selectors.button);

                childDiv.toggleClass(selectors.bottomContainerOpen);

                button.find(selectors.cmpButton).toggleClass(selectors.rotateButton);
            });
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new FAQContainer({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                // needed for IE
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function (addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function (element) {
                                new FAQContainer({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
}());
