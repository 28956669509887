import $ from 'jquery';
import Handlebars from 'handlebars';
import * as slick from 'slick-carousel';
import assetsService from "../../site/services/assetsService";

(function() {
    "use strict";
    var selectors = { 
        self:      ".cmp-speaker",
        speakerCardID: "#cmp-speaker__card-template",
        speakerContainer: ".card-slider",
        modal: ".cmp-speaker__modal",
        card: ".cmp-speaker__click",
        close: ".cmp-speaker__modal-close",
        className: ".cmp-speaker__name-link",
        classRole: ".cmp-speaker__modal-profession",
        classEnterprise: ".cmp-speaker__modal-enterprise",
        classLinkedin: ".cmp-speaker__modal-linkedin",
        classDescription: ".cmp-speaker__modal-links",
        classImage: ".cmp-speaker__image-link",
        loadMore: ".cmp-speaker__button",
        listMore: ".cmp-speaker__list",
        loadMoreHide: "cmp-speaker__button--hide",
        classOpen: "cmp-speaker__modal--open",
        shadow: "cmp-speaker__modal--shadow",
        slide: "cmp-speaker--slide",
        body: "body",
        slideRows: 1
    };
    
    var componentIsSlide  = "";
    var DATA_CONTENT = "";

    function mountModal(card, $element){
        var decodedDescription = decodeURIComponent($(card).data("description"));
        const modal = {
            name: $(card).data("firstname") + ' ' + $(card).data("lastname"),
            role: $(card).data("role"),
            description: decodedDescription,
            image: $(card).data("image"),
            enterprise: $(card).data("enterprise"),
            linkedin: $(card).data("linkedin"),
       }
        $element.find(selectors.className).html(modal.name);
        $element.find(selectors.classRole).html(modal.role);
        $element.find(selectors.classEnterprise).html(modal.enterprise);
        $element.find(selectors.classDescription).html(modal.description);
        $element.find(selectors.classImage).attr("src", modal.image);

        if(modal.linkedin) {
            $element.find(selectors.classLinkedin).show();
            $element.find(selectors.classLinkedin).attr("href", modal.linkedin);
        }else{
            $element.find(selectors.classLinkedin).hide();
        }

        $element.find(selectors.modal).addClass(selectors.classOpen);
    }

    function compare( a, b ) {
    if ( a.firstName < b.firstName ){
        return -1;
    }
    if ( a.firstName > b.firstName ){
        return 1;
    }
    return 0;
    }

    function compareName(a, b) {
        if (a['firstName'] < b['firstName']) return -1;
        if (a['firstName'] > b['firstName']) return 1;
        return 0;
    }

    function template(e) {
        var description = (e.properties.elements &&  e.properties.elements.description && e.properties.elements.description.value ) ? e.properties.elements.description.value : "";
        var escapedDescription= encodeURIComponent(description);
        return `
        <div class="cmp-speaker__card cmp-speaker__click" data-description="${e.properties.elements.description && e.properties.elements.description.value != undefined ? escapedDescription : " "}" 
            data-firstname="${ e.properties.elements.firstName && e.properties.elements.firstName.value != undefined  ? e.properties.elements.firstName.value : " " }" 
            data-lastname="${e.properties.elements.lastName && e.properties.elements.lastName.value != undefined ? e.properties.elements.lastName.value : " "}"
            data-image="${e.properties.elements.image.value }" data-role="${e.properties.elements.role && e.properties.elements.role.value != undefined ? e.properties.elements.role.value : " "}" 
            data-enterprise="${e.properties.elements.enterprise && e.properties.elements.enterprise.value != undefined ?  e.properties.elements.enterprise.value : " " }" 
            data-linkedin="${e.properties.elements.linkedin && e.properties.elements.linkedin.value != undefined ? e.properties.elements.linkedin.value : " " }">
                <div class="cmp-speaker__card-image">
                    <img src="${ e.properties.elements.image.value }">
                </div>
                <div class="cmp-speaker__card-content">
                    <div class="cmp-speaker__title">
                            <h4>${ e.properties.elements.firstName && e.properties.elements.firstName.value != undefined  ? e.properties.elements.firstName.value : " " }</h4>
                            <h4>${e.properties.elements.lastName && e.properties.elements.lastName.value != undefined ? e.properties.elements.lastName.value : " "}</h4>
                    </div>
                    <div class="cmp-speaker__role">
                        <p>${e.properties.elements.role && e.properties.elements.role.value != undefined ? e.properties.elements.role.value : " " }</p>
                    </div>
                </div>
        </div>`;
    }

    function getSpeakers($element) {
        const path = $element.data("path");
        const limit = $element.data("limit");
        const order = $element.data("order");
        var contents = { data: [ ] };
       
            return  assetsService.getAssets(path, parseInt(limit)).then((data) => {
                DATA_CONTENT = data.entities;

                if(order == "alphabetical") {
                    contents.data = contents.data.sort(compareName);
                }
            });
    }

    function loadContent($element) {
        $(DATA_CONTENT).each(function () {
            $element.find(selectors.listMore).append(template(this));
        });

        var $rows = $element.parent().data('rows') ? $element.parent().data('rows') : null
        var settings = {
            dots: false,
            infinite: true,
            rows: selectors.slideRows,
            speed: 500,
            slidesToShow: 4,
            centerMode: false,
            variableWidth: false,
            autoplay: false,
            responsive: [{
                breakpoint: 769,
                settings: {
                    autoplay: true,
                    autoplaySpeed: 4000,
                }
            }]
        };

        if(componentIsSlide){
            $element.find(selectors.speakerContainer).slick({
                ...settings
            });

            if($rows !== null) {
                settings['responsive'] = [
                    {
                        ...settings['responsive'],
                        settings: {
                            slidesToShow: 3,
                            autoplay: false,
                            autoplaySpeed: 4000,
                            prevArrow: "<i class='slick-prev pull-left arrow-left' aria-hidden='true'></i>",
                            nextArrow: "<i class=' slick-next pull-right arrow-left' aria-hidden='true'></i>",
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 3,
                        }
                    }                        
                ]

                $element.find(selectors.speakerContainer).slick('destroy');
                $element.find(selectors.speakerContainer).slick({
                    ...settings,
                    rows: $rows,
                });
            }
        }
    }

    function searchFlow($element) {
        $element.find(selectors.listMore).html("");

        getSpeakers($element)
            .then(() => {
                loadContent($element);
            })
            .then(() => {
                $element.find(selectors.card).on("click",function(){
                    mountModal(this, $element);
                    $(selectors.body).addClass(selectors.shadow);
                });
                $element.find(selectors.close).on("click", function(){
                    $element.find(selectors.modal).removeClass(selectors.classOpen);
                    $element.find(selectors.body).removeClass(selectors.shadow);
                    $(selectors.body).removeClass(selectors.shadow);
                })
            });
    }
        
    function Speaker(config) {
        function init(config) {
            const $element = $(config.element);
            componentIsSlide = $element.parent().hasClass(selectors.slide);

            searchFlow($element);
        }
        if (config && config.element) {
            init(config);
        }
    };

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new Speaker({ element: elements[i] });
        }
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body             = document.querySelector("body");
        var observer         = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) { 
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new Speaker({ element: element });
                            });
                        }
                    });
                }
            });
        });
        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    };
    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    };
}());