import httpServiceRequest from './httpServiceRequest';

function getContent(resourcePath, parameters, selector) {
    const type = 'json';
    const url = resourcePath + '.' + selector + '.' + type;
    return httpServiceRequest.get(url, parameters)
}

function getContentAgro(resourcePath, parameters) {
    const selector = 'searchAgro';
    const type = 'json';
    const url = resourcePath + '.' + selector + '.' + type;
    return httpServiceRequest.get(url, parameters)
}
function getContentArticle(resourcePath, parameters) {
    const selector = 'landingpagearticle';
    const type = 'json';
    const url = resourcePath + '.' + selector + '.' + type;
    return httpServiceRequest.get(url, parameters)
}

export default { getContent,getContentAgro, getContentArticle};
