import $ from "jquery";
import contentSearchService from "../../site/services/contentSearchService";

(function () {
    "use strict";

    var selectors = {
        self: ".cmp-contentsearch",
        loading: "loading",
        contentsList: ".cmp-contentsearch__list",
        pagination: ".cmp-contentsearch__pagination",
        paginationList: ".cmp-contentsearch__paginationlist",
        nextButtonPagination: ".cmp-contentsearch__paginationbutton--next",
        inputControlSearch: "input[name='in-search']",
        editable: "cmp-contentsearch--editmode",
        containerSearch: '.cmp-content__search--exportBrasil',
        buttonMore: '.cmp-button__export-brasil--more',
        displayNone: 'cmp-content__search--exportBrasil-dnone',
        contentSearch: '.cmp-contentsearch__search',
        filterText: '.cmp-contentsearch__filter-text',
        filterClose: '.cmp-contentsearch__filter-clear'
    };

    var offset = 0;

    function handleRenderContents(results, $element) {
        $element.removeClass(selectors.loading);             
        results.forEach(function(item) {
            if(this.title != "") {
                $element.find(selectors.contentsList).append(templateNews(item));
            }
        });
    }

    function templateNews(item){
        return `<li class="cmp-contentsearch__item">
            <a href="${item.url}" class="cmp-list__cards-item cmp-content__card" target="_blank" rel="noopener noreferrer">
                <img class="cmp-content__card-image" src="${item.thumbnail}"/>
                <h4 class="cmp-content__card-title">${item.title}</h4>
                <span class="cmp-content__card-date">${convertDate(item.contentCreationDate)}</span>
            </a>
        </li>`;
    }

    function handlePagination($element, totalItems) {
        const $perPage = $element.data("results");
        $element.find(selectors.pagination).attr("data-totalpage", Math.ceil(totalItems / parseInt($perPage)));
        $element.find(selectors.pagination).attr("data-maxvisiblebuttons", 5);
        $element.find(selectors.paginationList).html("");
        $element.find(selectors.paginationList).append(
            `<button class="cmp-contentsearch-button__loading cmp-contentsearch__paginationbutton--next">Carregar mais</button>`
        );
    }

    function convertDate(dateRaw) {
        const date = new Date(dateRaw);
        const day = zeroFill(date.getUTCDate(), 2);
        const month = zeroFill(date.getUTCMonth() + 1, 2);
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }

    function zeroFill(number, length) {
        const fill = new Array(1 + length).join('0');
        return (fill + number).slice(-fill.length);
    }

    function handleGetContents($element) {
        const resourcePath = $element.data("path");
        const selector = $element.data("selector");
        const $perpage = $element.data("results");
        const $fulltext = $element.attr("data-fulltext");
        const theme = $element.data('theme');
        const baseurl = `${resourcePath}.${selector}.json?offset=${offset}&limit=${$perpage}&fulltext=${$fulltext}`;

        var parameters = {
            "contentTypeTag": theme
        }

        return contentSearchService.getContentSearchExportBrasil(baseurl, parameters).then((request) => {
            return request;
        });
    }

    function handleRender($element, data) {
        handlePagination($element, data.total);
        handleRenderContents(data.data, $element);   
    }

    function handleInit($element) {
        handleGetContents($element).then((data) => {
            handleRender($element, data);
        });
    }

    function handleConentUpdate($element) {
        const $currentPage = parseInt($element.find(selectors.pagination).attr("data-currentpage"));
        const page = $currentPage - 1;
        const $offset = parseInt(page * $element.attr("data-results"));
        $element.find(selectors.pagination).attr("data-offset", $offset);
        offset = $offset;
        handleInit($element);
    }

    function handleFilterButton($element, containerSearch) {
        $element.find(selectors.paginationList).on('click', selectors.nextButtonPagination, function() {
            const button = $(this);
            const total = $element.find(selectors.pagination).attr("data-totalpage");
            const page = $element.find(selectors.pagination).attr("data-currentpage");
            const $pagination = $element.find(selectors.pagination);
            const currentPagination = $pagination.attr("data-currentpage");

            if(parseInt(page) < parseInt(total)) {
                $element.removeClass(selectors.loading);
                $pagination.attr("data-currentpage", parseInt(currentPagination) + 1);
                button.text("Carregando ...")
                button.prop('disabled', true);

                handleConentUpdate($element);
            } else {
                button.remove();
                containerSearch.find(selectors.buttonMore).removeClass(selectors.displayNone);
            }
        });    
    }

    function handleFilterInput($element, containerSearch) {
        var timer;
        $element.find(selectors.inputControlSearch).on('keyup', function() {
            clearTimeout(timer);
            const input =  $(this);
            $element.attr("data-fulltext", input.val());
            $element.find(selectors.pagination).attr("data-offset", 0);
            $element.find(selectors.pagination).attr("data-currentpage", 1);
            offset = 0;
           
            timer = setTimeout(function() {
                input.prop('disabled', true);
                $element.find(selectors.contentsList).html("");
                $element.find(selectors.paginationList).addClass(selectors.displayNone);
                containerSearch.find(selectors.buttonMore).addClass(selectors.displayNone);
                $element.addClass(selectors.loading);
                $element.find(selectors.contentSearch).find(selectors.filterText).remove();

                handleGetContents($element).then((data) => {
                    input.prop('disabled', false);
                    $element.removeClass(selectors.loading);
                    handleViewFilter($element, input.val());
                    containerSearch.find(selectors.buttonMore).removeClass(selectors.displayNone);
                    handleRender($element, data);

                    if(data.total > 8) {
                        $element.find(selectors.paginationList).removeClass(selectors.displayNone);
                    } else {
                        containerSearch.find(selectors.buttonMore).removeClass(selectors.displayNone);
                    }
                });
            }, 2500);
        });
    }

    function handleViewFilter($element, text) {
        $element.find(selectors.inputControlSearch).val("");
        $element.find(selectors.contentSearch).find(selectors.filterText).remove();
        $element.find(selectors.contentSearch).append(`
            <p class="cmp-contentsearch__filter-text">Exibindo resultados para "${text}" <span class="cmp-contentsearch__filter-clear">Limpar</span>   
        `);
    }

    function handleClearFilter($element, containerSearch) {
        $element.find(selectors.contentSearch).on('click', selectors.filterClose, function() {
            const input = $element.find(selectors.inputControlSearch);
            input.val("");
            input.prop("disabled",true);
            $element.find(selectors.contentSearch).find(selectors.filterText).remove();
            $element.addClass(selectors.loading);
            $element.find(selectors.contentsList).html("");
            $element.find(selectors.paginationList).addClass(selectors.displayNone);
            containerSearch.find(selectors.buttonMore).addClass(selectors.displayNone);
            $element.attr("data-fulltext", "");
            handleGetContents($element).then((data) => {
                input.prop("disabled",false);
                $element.removeClass(selectors.loading);
                handleRender($element, data);
                $element.find(selectors.paginationList).removeClass(selectors.displayNone);
            });
        });
    }

    function contentSearch(config) {
        function init(config) {
            const $element = $(config.element);
            const $pagination = $element.find(selectors.pagination);
            const containerSearch = $(selectors.containerSearch);
            $pagination.attr("data-offset", 0);
            $element.attr("data-fulltext", "");      
            containerSearch.find(selectors.buttonMore).addClass(selectors.displayNone);
            $element.addClass(selectors.loading);
            
            const selector = $element.data('selector');
            if(selector === "searchNewsExportBrasil") {
                handleInit($element);
                handleFilterButton($element, containerSearch);
                handleFilterInput($element, containerSearch);
                handleClearFilter($element, containerSearch);
            }
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new contentSearch({ element: elements[i] });
        }

        var MutationObserver =
            window.MutationObserver ||
            window.WebKitMutationObserver ||
            window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function (addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(
                                addedNode.querySelectorAll(selectors.self)
                            );
                            elementsArray.forEach(function (element) {
                                new contentSearch({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true,
        });
    }
 
    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
})();