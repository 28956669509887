import $ from 'jquery';

(function() {
    "use strict";

    var selectors = {
        self: '.cmp-tabwsl',
        modal: '.cmp-modal__wslcards',
        btn: 'cmp-modal__wslcards_open',
        buttonclose: '.cmp-modal__wslcards-close',
        card: '.cmp-tabwsl__tab',
        contentsList: '.cmp-tabwsl__tab',
        modalBody: '.cmp-modal__wslcards-content'
    };
  

  function modalClose($element) {
        $element.on('click',selectors.buttonclose, function() {
            $element.find(selectors.modal).removeClass(selectors.btn);
        });
    }

    function modalOpen($element) {
        $element.find(selectors.contentsList).on('click', function() {
            const modal = $element.find(selectors.modal);
               
            var titlemodal = $(this).data('titlemodal');
            var image = $(this).data('image');   
            var descriptionmodal = $(this).data('descriptionmodal'); 
            modal.find(selectors.modalBody).html("")
            modal.find(selectors.modalBody).append(templateModal(titlemodal, image, descriptionmodal));
            $element.find(selectors.modal).addClass(selectors.btn);
        });

    }
    
    function templateModal(titlemodal, image, descriptionmodal) {
        return `
        <div class="cmp-modal__header cmp-modal__header-wsl">
                    <div class="cmp-modal__close cmp-modal__wslcards-close"></div>
                    <img class="cmp-modal__header-image cmp-modal__header-image-wsl" src="${image}">
                </div> 
                <div class="cmp-modal__wslcards-render">
                    <h3 class="cmp-modal__wslcards-titlemodal">${titlemodal != undefined ? titlemodal : ""}</h3>
                    <p class="cmp-modal__wslcards-descriptionmodal">${descriptionmodal != undefined ? descriptionmodal : ""}</p>
                    <div class='cmp-modal__wslcards-bar'>
                    </div>
                </div>
            </div>
        `;
    }

    function ModalWslCards(config) {

        function init(config) {
            const $element = $(config.element);
          
            modalClose($element); 
            modalOpen($element);
        }
        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new ModalWslCards({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body             = document.querySelector("body");
        var observer         = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                // needed for IE
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new ModalWslCards({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }

}());