import $ from 'jquery';

(function() {
    "use strict";

    var selectors = {
        self: '.cmp-footer-shetrades__container-registration',
        banner: '#banner',
        buttonBanner: '#button-banner',
        footer: '#footer',
        scrolled: 'scrolled--registration'
    };

    var fixedMenuHeight = 85;

    function isInViewport($element, offset = 0) {
        var elementTop = $element.offset().top;
        var elementBottom = elementTop + $element.outerHeight();
        var viewportTop = $(window).scrollTop() + offset;
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    }

    function Registration(config) {

        function init(config) {
            const $element = $(selectors.self);
			
            var footerPosition = $(selectors.footer).offset().top;
            const buttonBanner = $(selectors.buttonBanner);

            $(window).on('scroll', function() {
                var scrollPosition = Math.ceil($(window).scrollTop());
                var windowHeight = $(window).height();

                footerPosition = $(selectors.footer).offset().top;

                if (!isInViewport(buttonBanner, fixedMenuHeight) && (scrollPosition + windowHeight) < footerPosition) {
                    $element.addClass(selectors.scrolled);
                } else {
                    $element.removeClass(selectors.scrolled);
                }
            });
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new Registration({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body             = document.querySelector("body");
        var observer         = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new Registration({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }

}());
