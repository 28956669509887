import $ from 'jquery';

(function() {
    "use strict";

    const selectors = {
        self: '#cmp-automaticslide',
    };

    function AutomaticSlide(config) {
        const $element = $(config.element);
        let currentPosition = 0;

        const $content = $element.find('.aem-Grid');
        const elementWidth = $content.width();
        $content.clone().appendTo($element);

        function animateSlide() {
            currentPosition -= 0.3;
            $element.css('left', currentPosition + 'px');

            if (currentPosition <= -elementWidth) {
                currentPosition = 0;
                $element.css('left', '0');

                setTimeout(function() {
                    animateSlide();
                }, 1000);
            } else {
                requestAnimationFrame(animateSlide);
            }
        }

        setTimeout(animateSlide, 1000);
    }

    function onDocumentReady() {
        $(selectors.self).each(function(index, element) {
            new AutomaticSlide({ element: element });
        });

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new AutomaticSlide({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }

}());
