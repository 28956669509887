import $ from 'jquery';
import Handlebars from 'handlebars';
import * as slick from 'slick-carousel';
import assetsService from "../../site/services/assetsService";

(function() {
    "use strict";

    var selectors = {
        self:      '.cmp-cardnumber--inter-women-and-business-infor',
        cardContainer: ".cmp-cardnumber",
    };

    function getcardContainer($element) {
        $($element).find(selectors.cardContainer).slick({
            infinite: true,
            arrows: false,
            dots: false,
            speed: 100,
            slidesToScroll: 1,
            autoplay: true,
            centerMode: true,
            autoplaySpeed: 2000,
            variableWidth: true,
        });
           
    };

    function cardNumber(config) {
        function init(config) {
            const $element = $(config.element);
            
            getcardContainer($element);
        }

        if (config && config.element) {
            init(config);
        }
    };

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);

        for (var i = 0; i < elements.length; i++) {
            new cardNumber({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body             = document.querySelector("body");
        var observer         = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new partner({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    };

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    };

}());
