import $ from 'jquery';
import { scrollToTarget } from '../../site/utils/utils';


(function () {
    "use strict";

    var selectors = {
        self: '.cmp-main-navigation--cb-elasexportam',
        button: '.cmp-button__elasexportam .cmp-button',
        navegation: '.cmp-container__elasexportamnavigation',
        navegationOpen: 'cmp-container__elasexportamnavigation__open'
    };

    function HeaderExportaMais(config) {
        function init(config) {
            const $element = $(config.element);
            const $mobileNavigation = $(selectors.navegation);

            $element.find(selectors.button).on('click', function (e) {
                e.stopPropagation();
                $mobileNavigation.toggleClass(selectors.navegationOpen);
            });

            $(document).on('click', function (e) {
                if (!$mobileNavigation.is(e.target) && $mobileNavigation.has(e.target).length === 0) {
                    $mobileNavigation.removeClass(selectors.navegationOpen);
                }
            });

            $mobileNavigation.find('a').on('click', function (e) {
                e.preventDefault();

                const targetId = $(this).attr('href').substring(1);
                const offset = 130;
                scrollToTarget(targetId, offset, 270, selectors);
            });
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new HeaderExportaMais({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                // needed for IE
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function (addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function (element) {
                                new HeaderExportaMais({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }

}());
