import $ from 'jquery';
import Handlebars from 'handlebars';
import * as slick from 'slick-carousel';
import assetsService from "../../site/services/assetsService";

(function() {
    "use strict";

    var selectors = {
        self:      '.cmp-partners__container',
        partnerCard: "#cmp-partners__card",
        cardContainer: ".cmp-partners__card-container",
        partnerList: ".cmp-partners__list"
    };

    function getpartners($element) {
        const path = $element.find(selectors.partnerList).data("path");
        const limit = $element.find(selectors.partnerList).data("limit");
        var contents = { data: [ ] };
        const carosel = $element.find(selectors.partnerList).data("list");
        
        assetsService.getAssets(path, limit).then((data) => {
            $(data.entities).each((i,e) => {
                contents.data.push({
                    "image": e.properties.elements.image.value,
                    "name": e.properties.elements.name.value,
                    "link": e.properties.elements.link.value,
                });
            });

            const partnerTemplate = $(selectors.partnerCard).html();
            const compiledpartnerTemplate = Handlebars.compile(partnerTemplate);
            const $cardList = $element;
            $cardList.html(compiledpartnerTemplate(contents));
           
            $($element).find(selectors.cardContainer).slick({
                infinite: carosel,
                arrows: true,
                dots: true,
                speed: 300,
                slidesToScroll: 1, 
                autoplay: true,
                centerMode: false,
                autoplaySpeed: 2000,
                variableWidth: true,
            });
        });
    };

    function partner(config) {
        function init(config) {
            config.element.removeAttribute('data-cmp-is');
            const $element = $(config.element);
            getpartners($element);
        }

        if (config && config.element) {
            init(config);
        }
    };

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);

        for (var i = 0; i < elements.length; i++) {
            new partner({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body             = document.querySelector("body");
        var observer         = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new partner({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    };

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    };

}());
