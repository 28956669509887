import $ from "jquery";
import httpServiceRequest from "../../site/services/httpServiceRequest";
import * as slick from 'slick-carousel';

(function () {
    "use strict";

    var selectors = {
        self: ".cmp-searcheventlandingpage",
        loading: "loading",
        contentsList: ".cmp-searcheventlandingpage__list",
        loadingCmp: ".cmp-searcheventlandingpage__loading",
        loadingCmpActive: "cmp-searcheventlandingpage__loading--active"
    };
    const cmpEventAgro = $(selectors.self);

    var TOTAL_RESULT = "";
    var DATA_CONTENT = "";

    async function getResults($element) {
        var RESOURCE_PATH = $element.data("path");
        const selector = 'eventLandingPage';
        const type = 'json';
        const url = RESOURCE_PATH + '.' + selector + '.' + type;
        var results = $element.data("results");
        var status = $element.data("status");
        var eventavailable = $element.data("eventavaible");
        var path = $element.data("rootpath");
        var eventformat = $element.data("eventformat");
        var product = $element.data("product");
        var tagfield = $element.data("tagfield");
        var tag = $element.data("tag");

        var parameters = {
            status: status,
            eventFormat: eventformat,
            path: path,
            limit: results,
            eventAvailable: eventavailable !== 'all' ? eventavailable : "",
            product: product,
            tag: tag,
            tagField: tagfield,
        };

        return httpServiceRequest
            .get(url, parameters)
            .then((data) => {
                TOTAL_RESULT = data.total;
                DATA_CONTENT = data.data;
            });
    }

    function searchFlow($element) {
        $element.find(selectors.contentsList).html("");
        $element.find(selectors.loadingCmp).addClass(selectors.loadingCmpActive);
        getResults($element)
            .then(() => {
                loadContent($element);
                $element.find(selectors.loadingCmp).removeClass(selectors.loadingCmpActive);
            }).then(() => {
                loadCarousel($element);
            });;
    }

    function loadContent($element) {
        $(DATA_CONTENT).each(function () {
            $element.find(selectors.contentsList).append(template(this));
        });
    }

    function limitText(item) {
        return item.substring(0, 150).concat("...");
    }

    function epochToHumanDate(epoch) {
        const date = new Date(epoch);
        date.setMinutes(date.getMinutes() + 180);
        const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' });

        return formattedDate;
    }

    function template(item) {
        const divStyle = `
            background-image: url(${item.backgroundImage});
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        `;

        return `
        ${item.url ? `<div>
            <a href="${item.url}" target="_blank" class="cmp-searcheventlandingpage__link">
                <div class="cmp-searcheventlandingpage__item" style="${divStyle}">
                    <div>
                        ${item.title ? `<h3 class="cmp-searcheventlandingpage__item-title">${item.title}</h3>` : ""}
                        <div class="cmp-searcheventlandingpage__item-description">
                            ${limitText(item.shortDescription) ? `${limitText(item.shortDescription)}` : ""}
                        </div>
                        <ul class="cmp-searcheventlandingpage__item-datelist">
                            <li class="cmp-searcheventlandingpage__item-dateitem">
                                <span>Período de inscrição: ${epochToHumanDate(item.subscriptionStartDate)} a ${epochToHumanDate(item.subscriptionEndDate)}</span>
                            </li>
                            <li class="cmp-searcheventlandingpage__item-dateitem">
                                <span>Data do evento: ${item.startDate !== item.endDate ? epochToHumanDate(item.startDate) + " a " + epochToHumanDate(item.endDate) : epochToHumanDate(item.startDate)}</span>
                            </li>
                        </ul>
                        <span style="background-color: ${item.subscriptionStatusColor}" class="cmp-searcheventlandingpage__item-button">${item.subscriptionStatus}</span>
                    </div>
                </div>
            </a> </div>
        ` : ""}
        `;
    }

    function loadCarousel() {
        var windowWidth = $(window).width();
        if (windowWidth < 768) {
            cmpEventAgro.find(selectors.contentsList).slick({
                infinite: true,
                arrows: false,
                dots: true,
                autoplay: true,
                centerMode: true,
                autoplaySpeed: 2000,
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 415,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ],
            });
        }
    }

    function eventAgroList(config) {
        function init(config) {
            const $element = $(config.element);
            searchFlow($element);
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new eventAgroList({ element: elements[i] });
        }

        var MutationObserver =
            window.MutationObserver ||
            window.WebKitMutationObserver ||
            window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function (addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(
                                addedNode.querySelectorAll(selectors.self)
                            );
                            elementsArray.forEach(function (element) {
                                new eventAgroList({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true,
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
})();