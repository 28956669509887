import $ from "jquery";
import searchService from "../../site/services/searchService";

(function () {
    "use strict";

    var selectors = {
        self: ".cmp-landingpagearticle",
        loading: "loading",
        contentsList: ".cmp-landingpagearticle__list",
        pagination: ".cmp-landingpagearticle__pagination",
        paginationList: ".cmp-landingpagearticle__paginationlist",
        nextButtonPagination: ".cmp-landingpagearticle__paginationbutton",
        inputControlSearch: "input[name='in-search']",
        editable: "cmp-landingpagearticle--editmode",
        containerSearch: '.cmp-landingpagearticle__search--agro',
        buttonMore: '.cmp-button__agro--more',
        displayNone: 'cmp-landingpagearticle__search--agro-dnone',
        contentSearch: '.cmp-landingpagearticle__search',
        filterText: '.cmp-landingpagearticle__filter-text',
        filterClose: '.cmp-landingpagearticle__filter-clear'
    };

    let offset = 0;
    let nameTagContent = "";

    function handleRenderContents(results, $element) {
        $element.removeClass(selectors.loading);             
        const $contentsList = $element.find(selectors.contentsList);
        const initialItems = results.slice(0, 4);
        initialItems.forEach(item => {
            if(item.title !== "") {
                $contentsList.append(template(item));
            }
        });

        $element.data('remainingItems', results.slice(4));
    }

    function loadMoreItems($element) {
        const $contentsList = $element.find(selectors.contentsList);
        let remainingItems = $element.data('remainingItems') || [];
        const nextItems = remainingItems.slice(0, 4);
        remainingItems = remainingItems.slice(4);
        
        nextItems.forEach(item => {
            if (item.title !== "") {
                $contentsList.append(template(item));
            }
        });
        
        $element.data('remainingItems', remainingItems);
    
        if (remainingItems.length === 0) {
            $element.find(selectors.nextButtonPagination).hide();
        }
    }
    
    function convertDate(dateRaw) {
        const date = new Date(dateRaw);
        const day = zeroFill(date.getUTCDate(), 2);
        const month = zeroFill(date.getUTCMonth() + 1, 2);
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    
    function zeroFill(number, length) {
        return number.toString().padStart(length, '0');
    }

    function template(item){
        return `<li class="cmp-landingpagearticle__item">
            <a href="${item.pageURL}" class="cmp-list__cards-item cmp-landingpagearticle__card" target="_blank" rel="noopener noreferrer">
                <div class="cmp-landingpagearticle__card-image" style="background-image: url('${item.thumbnail}')"></div>
                <div class="cmp-landingpagearticle__card-content">
                    <span class="cmp-landingpagearticle__card-tag">${nameTagContent}</span>
                    <h4 class="cmp-landingpagearticle__card-title">${item.title}</h4>
                    <p class="cmp-landingpagearticle__card-text"> ${item.subtitle} </p>
                    <span class="cmp-landingpagearticle__card-date">${convertDate(item.contentCreationDate)}</span>
                </div>
            </a>
        </li>`;
    }

    function handlePagination($element, totalItems) {
        $element.find(selectors.pagination).attr("data-totalpage", Math.ceil(totalItems / 4));
        $element.find(selectors.pagination).attr("data-maxvisiblebuttons", 4);
        $element.find(selectors.paginationList).html("");
        $element.find(selectors.paginationList).append(
            `<button class="cmp-landingpagearticle-button__loading cmp-landingpagearticle__paginationbutton">Carregar mais</button>`
        );
    }

    function handleGetContents($element) {
        const resourcePath = $element.data("path");
        const $perpage = $element.data("results");
        const $fulltext = $element.attr("data-fulltext");
        const exportsector = $element.data("exportsector");
        nameTagContent = $element.data("nametagcontent"); 

        var parameters = {
            "fulltext": $fulltext,
            "offset": offset,
            "limit": $perpage,
            "tag": exportsector,
        }

        return searchService.getContentArticle(resourcePath, parameters).then((request) => {
            return request;
        });
    }

    function handleRender($element, data) {
        handlePagination($element, data.length);
        handleRenderContents(data, $element);     
    }

    function handleInit($element) {
        handleGetContents($element).then((data) => {
            handleRender($element, data);
        });
    }

    function handleFilterButton($element, containerSearch) {
        $element.find(selectors.paginationList).on('click', selectors.nextButtonPagination, function() {
            const button = $(this);
            const total = $element.find(selectors.pagination).attr("data-totalpage");
            const page = $element.find(selectors.pagination).attr("data-currentpage");
            const $pagination = $element.find(selectors.pagination);
            const currentPagination = $pagination.attr("data-currentpage");
    
            if (parseInt(page) < parseInt(total)) {
                $element.removeClass(selectors.loading);
                $pagination.attr("data-currentpage", parseInt(currentPagination) + 1);
                button.text("Carregando ...")
                button.prop('disabled', true);
                loadMoreItems($element);
                button.text("Carregar mais");
                button.prop('disabled', false);
            } else {
                button.remove();
                containerSearch.find(selectors.buttonMore).removeClass(selectors.displayNone);
            }
        });
    }
    function handleFilterInput($element, containerSearch) {
        var timer;
        $element.find(selectors.inputControlSearch).on('keyup', function() {
            clearTimeout(timer);
            const input =  $(this);
            $element.attr("data-fulltext", input.val());
            $element.find(selectors.pagination).attr("data-offset", 0);
            $element.find(selectors.pagination).attr("data-currentpage", 1);
            offset = 0;
            timer = setTimeout(function() {
                input.prop('disabled', true);
                $element.find(selectors.contentsList).html("");
                $element.find(selectors.paginationList).addClass(selectors.displayNone);
                containerSearch.find(selectors.buttonMore).addClass(selectors.displayNone);
                $element.addClass(selectors.loading);
                $element.find(selectors.contentSearch).find(selectors.filterText).remove();

                handleGetContents($element).then((data) => {
                    input.prop('disabled', false);
                    $element.removeClass(selectors.loading);
                    handleViewFilter($element, input.val());
                    containerSearch.find(selectors.buttonMore).removeClass(selectors.displayNone);
                    handleRender($element, data);
                    if(data.total > 8) {
                        $element.find(selectors.paginationList).removeClass(selectors.displayNone);
                    } else {
                        containerSearch.find(selectors.buttonMore).removeClass(selectors.displayNone);
                    }
                });
            }, 2000);
        });
    }

    function handleViewFilter($element, text) {
        $element.find(selectors.inputControlSearch).val("");
        $element.find(selectors.contentSearch).find(selectors.filterText).remove();
        $element.find(selectors.contentSearch).append(`
            <p class="cmp-landingpagearticle__filter-text">Exibindo resultados para "${text}" <span class="cmp-landingpagearticle__filter-clear">Limpar</span>   
        `);
    }

    function handleClearFilter($element, containerSearch) {
        $element.find(selectors.contentSearch).on('click', selectors.filterClose, function() {
            const input = $element.find(selectors.inputControlSearch);
            input.val("");
            input.prop("disabled",true);
            $element.find(selectors.contentSearch).find(selectors.filterText).remove();
            $element.addClass(selectors.loading);
            $element.find(selectors.contentsList).html("");
            $element.find(selectors.paginationList).addClass(selectors.displayNone);
            containerSearch.find(selectors.buttonMore).addClass(selectors.displayNone);
            $element.attr("data-fulltext", "");
            handleGetContents($element).then((data) => {
                input.prop("disabled",false);
                $element.removeClass(selectors.loading);
                handleRender($element, data);
                if (data.length > 4) {
                    containerSearch.find(selectors.buttonMore).removeClass(selectors.displayNone);
                }
            });
        });
    }

    function landingPageArticle(config) {
        function init(config) {
            const $element = $(config.element);
            const $pagination = $element.find(selectors.pagination);
            const containerSearch = $(selectors.containerSearch);
            $pagination.attr("data-offset", 0);
            $element.attr("data-fulltext", "");      
            containerSearch.find(selectors.buttonMore).addClass(selectors.displayNone);
            $element.addClass(selectors.loading);
            const selector = $element.data('selector');
            if(selector === "searchLandingPageArticle") {
                handleInit($element);
                handleFilterButton($element, containerSearch);
                handleFilterInput($element, containerSearch);
                handleClearFilter($element, containerSearch);
            }
            const $landingpagearticle = $element.find(selectors.self);
            $landingpagearticle.ready(function() {
                $(selectors.nextButtonPagination).on('click', function() {
                    loadMoreItems($element);
                });
            });
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new landingPageArticle({ element: elements[i] });
        }

        var MutationObserver =
            window.MutationObserver ||
            window.WebKitMutationObserver ||
            window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function (addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(
                                addedNode.querySelectorAll(selectors.self)
                            );
                            elementsArray.forEach(function (element) {
                                new landingPageArticle({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true,
        });
    }
    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
})();