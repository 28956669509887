import $ from 'jquery';
import * as slick from 'slick-carousel';

(function () {
    'use strict';
    var selectors = {
        self: '.cmp-eventwslcaroulsel',
        speakerCardID: '#cmp-eventwslcaroulsel__card-template',
        slide: '.cmp-eventwslcaroulsel--slide',
        listMore: '.cmp-eventwslcaroulsel__list',
        eventContainer: '.card-slider-eventwslcaroulsel',

        loadingCmp: ".cmp-eventwslcaroulsel__loading",
        loadingCmpActive: "cmp-eventwslcaroulsel__loading--active",
    };
    var offset = 0;
    var limit = 4;

    function EventWSLRequest($element) {
        const path = $element.data('path');
        var pathAssets = '/api/assets/';
        var splitPath =
            path.replace('/content/dam/', pathAssets).concat('', '.json');
        var data = { data: [] };

        $element.find(selectors.loadingCmp).addClass(selectors.loadingCmpActive);
        $.ajax({
            type: 'GET',
            url: splitPath,
            dataType: 'json',
            success: function (req) {
                $(selectors.eventContainer).empty();
                $(req.entities).each((i, e) => {
                    data.data.push({
                        image: e.properties.elements.eventImage.value,
                        subtitle: e.properties.elements.headerText.value,
                        title: e.properties.elements.eventTitle.value,
                        linkLabel: e.properties.elements.linkLabel.value,
                        link: e.properties.elements.link.value,
                        description: e.properties.elements.description.value,
                        socialMediaLabel: e.properties.elements.socialMediaLabel.value,
                        instagramLink: e.properties.elements.instagramLink.value,
                        facebookLink: e.properties.elements.facebookLink.value,
                        youtubeLink: e.properties.elements.youtubeLink.value,
                        twitterLink: e.properties.elements.twitterLink.value,
                        linkedinLink: e.properties.elements.linkedinLink.value,
                        emailLabel: e.properties.elements.emailLabel.value,
                        email: e.properties.elements.email.value,
                        path: path + '/' + e.properties.name,
                    });
                });

                $element.find(selectors.loadingCmp).removeClass(selectors.loadingCmpActive);

                $(data.data).each((i, e) => {
                    const eventCardTemplate = `
                    <div class="cmp-eventwslcaroulsel__cardcontainer ${i === data.data.length - 1 ? "cmp-eventwslcaroulsel__lastcardcontainer" : ""} ${i === 0 ? "cmp-eventwslcaroulsel__firstcardcontainer" : ""}">
                        <div class="cmp-eventwslcaroulsel__textcontainer">
                            <p class="cmp-eventwslcaroulsel__subtitle">${e.subtitle}</p>
                            <h4 class="cmp-eventwslcaroulsel__title">${e.title.replace(/<p>/g, '').replace(/<\/p>/g, '')} </h4>
                            <a class="cmp-eventwslcaroulsel__link" src="${e.link}" alt="${e.linkLabel}">${e.linkLabel}</a>
                        </div>
                        <div>
                            <img class="cmp-eventwslcaroulsel__image" src="${e.image}" alt="${e.title.replace(/<p>/g, '').replace(/<\/p>/g, '')}"/>
                        </div>
                    </div>
                    `
                    
                    const $eventCard = $(eventCardTemplate);
                    $eventCard.on("click", () => {
                        const modalEventTemplate = `
                        <div class="cmp-eventwslcaroulsel__modal">
                            <div class="cmp-eventwslcaroulsel__modalcontent">
                                    <div class="cmp-eventwslcaroulsel__modalimagecontainer">
                                        <img src="${e.image}" alt="${e.title.replace(/<p>/g, '').replace(/<\/p>/g, '')}" class="cmp-eventwslcaroulsel__modalimage" />
                                        <button class="cmp-eventwslcaroulsel__modalclosebutton" onclick="closeModal()"></button>
                                    </div>
                                    <div class="cmp-eventwslcaroulsel__modaltextcontainer">
                                        <h4 class="cmp-eventwslcaroulsel__modaltitle">${e.title.replace(/<p>/g, '').replace(/<\/p>/g, '')}</h4>
                                        <p class="cmp-eventwslcaroulsel__modaldescription"> ${e.description}</p>
                                        <a class="cmp-eventwslcaroulsel__modallink" src="${e.link}" alt="${e.title}">${e.linkLabel}</a>
                                    </div>
                                    <div class="cmp-eventwslcaroulsel__modalcontent-bar"></div>
                                    <div class="cmp-eventwslcaroulsel__modalsocialcontainer">
                                        <p class="cmp-eventwslcaroulsel__modalsociallabel">${e.socialMediaLabel}</p>
                                        <div class="cmp-eventwslcaroulsel__modalsocialiconcontainer">
                                            <a href='${e.instagramLink}' class="cmp-modal__wslsearch__rede__social_instagram" target="_blank" ><a/>
                                            <a href='${e.facebookLink}' class="cmp-modal__wslsearch__rede__social_facebook" target="_blank"><a/>
                                            <a href='${e.youtubeLink}' class="cmp-modal__wslsearch__rede__social_youtube" target="_blank" ><a/>
                                            <a href='${e.twitterLink}' class="cmp-modal__wslsearch__rede__social_twitter" target="_blank"><a/>
                                            <a href='${e.linkedinLink}' class="cmp-modal__wslsearch__rede__social_linkedin" target="_blank"><a/>
                                        </div>
                                    </div>
                                    <div class="cmp-eventwslcaroulsel__modalemaikcontainer">
                                        <p class="cmp-eventwslcaroulsel__modalemaillabel">${e.emailLabel}</p>
                                        <a class="cmp-eventwslcaroulsel__modalemail">${e.email}</a>
                                    </div>
                            </div>
                        </div>
                        `;
                        $(selectors.eventContainer).append(modalEventTemplate);
                    
                        $(".cmp-eventwslcaroulsel__modalclosebutton").on("click", function(event) {
                            if (event.target === this) {
                                closeModal();
                            }
                        });
                    });
                    
                    function closeModal() {
                        $(".cmp-eventwslcaroulsel__modal").remove();
                    }
                    
                    $(selectors.eventContainer).append($eventCard);
                })
                $element.find(selectors.eventContainer).slick({
                    dots: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 1,
                    centerMode: false,
                    variableWidth: true,
                });
            },
        });
    }

    function EventWSL(config) {
        function init(config) {
            const $element = $(config.element);
            limit = $element.data('limit') || 4;

            EventWSLRequest($element);

        }
        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new EventWSL({ element: elements[i] });
        }
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body = document.querySelector('body');
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function (addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function (element) {
                                new EventWSL({ element: element });
                            });
                        }
                    });
                }
            });
        });
        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true,
        });
    }
    if (document.readyState !== 'loading') {
        onDocumentReady();
    } else {
        document.addEventListener('DOMContentLoaded', onDocumentReady);
    }
})();
