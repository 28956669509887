import $ from 'jquery';
import slick from 'slick-carousel';
import assetsService from "../../site/services/assetsService";

(function () {
    "use strict";

    var selectors = {
        self: '.cmp-testimony__container',
        testimonyCard: "#cmp-testimony__card",
        cardContainer: ".cmp-testimony__card-container",
        testimonyList: ".cmp-testimony__list"
    };

    function initSlick($element) {
        var sliderOptions = {
            dots: true,
            infinite: true,
            speed: 600,
            slidesToShow: 1,
            adaptiveHeight: true,
            arrows: true,
            prevArrow: '<button class="carousel-prev"></button>',
            nextArrow: '<button class="carousel-next"></button>',
        };
        $element.find(selectors.cardContainer).slick(sliderOptions);
    }

    function gettestimony($element) {
        const path = $element.find(selectors.testimonyList).data("path");
        const limit = $element.find(selectors.testimonyList).data("limit");
        var contents = { data: [] };

        async function fetchAssets() {
        const data = await assetsService.getAssets(path, limit);
        $(data.entities).each((i, e) => {
            contents.data.push({
                "photo": e.properties.elements.photo.value,
                "name": e.properties.elements.name.value,
                "testimony": e.properties.elements.testimony.value,
            });
        });

        const $cardList = $element.find(selectors.cardContainer);
            contents.data.forEach(item => {
                const $testimonyCard = $('<div class="cmp-testimony__card"></div>');
                $testimonyCard.append(`
                    <div class="cmp-testimony__infor"> 
                        <img class="cmp-testimony__image" src="${item.photo}" alt="${item.name}"> 
                        <div class="cmp-testimony__text"> 
                            <p class="cmp-testimony__description">${item.testimony}</p>
                            <h4 class="cmp-testimony__name">${item.name}</h4>
                        </div>
                    </div>`);
                $element.find(selectors.cardContainer).append($testimonyCard);
            });

            initSlick($element);
        }

        fetchAssets();
    }

    function testimony(config) {
        function init(config) {
            config.element.removeAttribute('data-cmp-is');
            const $element = $(config.element);
            gettestimony($element);
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);

        for (var i = 0; i < elements.length; i++) {
            new testimony({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            var nodesArray = [].slice.call(mutation.addedNodes);
            if (nodesArray.length > 0) {
            nodesArray.forEach(function (addedNode) {
                if (addedNode.querySelectorAll) {
                var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                elementsArray.forEach(function (element) {
                    new testimony({ element: element });
                    gettestimony($(element));
                });
                }
            });
            }
        });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
}());