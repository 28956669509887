import $ from 'jquery';

(function() {
    "use strict";

    var selectors = {
        self: '#cmp-footer-elasexportam__container-registration',
        banner: '#banner',
        scrolled: 'scrolled'
    };

    function Registration(config) {

        function init(config) {
            const $element = $(selectors.self);
			
            var fixedComponentHeight = $(selectors.banner).outerHeight();

            $(window).on('scroll', function() {
            if ($(window).scrollTop() > fixedComponentHeight) {
                $element.addClass(selectors.scrolled);
            } else {
                $element.removeClass(selectors.scrolled);
            }
            });
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new Registration({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body             = document.querySelector("body");
        var observer         = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new Registration({ element: element });
                            });
                        }
                    });
                }
            });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }

}());
