import $ from 'jquery';

function get(url, parameters) {
    return new Promise((resolve, reject) => {
        $.ajax({
        url: url,
        data: parameters,
        dataType: 'json',
        success: function (data) {
            resolve(data);
        },
        error: function (error) {
            reject(error);
        },
        });
    });
}

function post(url, parameters) {
    return new Promise((resolve, reject) => {
        $.ajax({
        url: url,
        type: 'POST',
        data: parameters,
        dataType: 'json',
        success: function (data) {
            resolve(data);
        },
        error: function (error) {
            reject(error);
        },
        });
    });
}

export default { get, post };
