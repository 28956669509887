import httpServiceRequest from './httpServiceRequest';

const type  = 'json';

function getContentSearchDesignerExport(path, selector, parameters) {
    var mountUrl = `offset=${parameters.offset}&limit=${parameters.limit}${parameters.city ? `&city=${parameters.city}` : ''}${parameters.tags ? `&tags=${parameters.tags.join('&tags=')}` : ''}&fulltext=${parameters.fulltext}`;
    const url =  `${path}.${selector}.${type}?${mountUrl}`;
    return httpServiceRequest.get(url);
}

function getContentSearchExportBrasil(url, parameters) {
    return httpServiceRequest.get(url, parameters);
}

export default { getContentSearchDesignerExport, getContentSearchExportBrasil };