import $ from 'jquery';

(function() {
    "use strict";

    var selectors = {
        self: '#btn-scrolltop',
    };

    function ScrollTop(config) {
        function init(config) {
            $(config.element).click(() => {
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
        }
        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);
        for (var i = 0; i < elements.length; i++) {
            new ScrollTop({ element: elements[i] });
        }
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body             = document.querySelector("body");
        var observer         = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                var nodesArray = [].slice.call(mutation.addedNodes);
                if (nodesArray.length > 0) {
                    nodesArray.forEach(function(addedNode) {
                        if (addedNode.querySelectorAll) {
                            var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                            elementsArray.forEach(function(element) {
                                new ScrollTop({ element: element });
                            });
                        }
                    });
                }
            });
        });
        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }
    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
}());
