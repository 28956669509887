import $ from 'jquery';
import assetsService from "../../site/services/assetsService";

(function () {
    "use strict";

    var selectors = {
        self: '.cmp-mentors',
        mentorList: ".cmp-mentors__list"
    };

    function getmentors($element) {
        const path = $element.find(selectors.mentorList).data("path");
        const limit = $element.find(selectors.mentorList).data("limit");
        var contents = { data: [] };

        async function fetchAssets() {
            const data = await assetsService.getAssets(path, limit);
            $(data.entities).each((i, e) => {
                contents.data.push({
                    "image": e.properties.elements.image.value,
                    "name": e.properties.elements.name.value,
                    "description": e.properties.elements.description.value,
                    "link": e.properties.elements.link.value,
                });
            });

            contents.data = contents.data.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            }); 

            $element.find(selectors.mentorList).html('');
            contents.data.forEach(item => {
                const $mentorsCard = $(`
                        <div class="cmp-mentors__infor"> 
                            <img class="cmp-mentors__image" src="${item.image}" alt="${item.name}"> 
                            <div class="cmp-mentors__text"> 
                                <h4 class="cmp-mentors__name">${item.name}</h4>
                                <p class="cmp-mentors__description">${item.description}</p>
                                <a href="${item.link}" class="cmp-mentors__rede" target="_blank">Linkedin</a>
                            </div>
                        </div>`);
                $element.find(selectors.mentorList).append($mentorsCard);
            });
        }

        fetchAssets();
    }

    function Mentors(config) {
        function init(config) {
            config.element.removeAttribute('data-cmp-is');
            const $element = $(config.element);

            getmentors($element);
        }

        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var elements = document.querySelectorAll(selectors.self);

        for (var i = 0; i < elements.length; i++) {
            new Mentors({ element: elements[i] });
        }

        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        var body = document.querySelector("body");
        var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            var nodesArray = [].slice.call(mutation.addedNodes);
            if (nodesArray.length > 0) {
            nodesArray.forEach(function (addedNode) {
                if (addedNode.querySelectorAll) {
                    var elementsArray = [].slice.call(addedNode.querySelectorAll(selectors.self));
                    elementsArray.forEach(function (element) {
                        new Mentors({ element: element });
                    });
                }
            });
            }
        });
        });

        observer.observe(body, {
            subtree: true,
            childList: true,
            characterData: true
        });
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
}());