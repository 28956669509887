import $ from 'jquery';
import assetsService from "../../site/services/assetsService";

(function () {
    'use strict';
    var selectors = {
        self: '.cmp-speaker__modal',
        close: ".cmp-speaker__modal-close",
        className: ".cmp-speaker__name-link",
        classRole: ".cmp-speaker__modal-profession",
        classEnterprise: ".cmp-speaker__modal-enterprise",
        classLinkedin: ".cmp-speaker__modal-linkedin",
        classDescription: ".cmp-speaker__modal-links",
        classImage: ".cmp-speaker__image-link",
        classOpen: "cmp-speaker__modal--open",
        shadow: "cmp-speaker__modal--shadow",
        body: 'body,html',
        showSpeakerDetails: '.js-show-speaker-details',
        modalContent: 'cmp-speaker__modal-content'
    };

    function showSpeakerDetails(path, $element) {
        assetsService.getAssets(path).then((data) => {
            var elements = data.properties.elements;
            const modal = {
                name: elements.firstName.value + ' ' + elements.lastName.value,
                role: elements.role.value,
                description: elements.description.value,
                image: elements.image.value,
                enterprise: elements.enterprise.value,
                linkedin: elements.linkedin.value,
           }

            $element.find(selectors.className).html(modal.name);
            $element.find(selectors.classRole).html(modal.role);
            $element.find(selectors.classEnterprise).html(modal.enterprise);
            $element.find(selectors.classDescription).html(modal.description);
            $element.find(selectors.classImage).attr("src", modal.image);

            if(modal.linkedin) {
                $element.find(selectors.classLinkedin).show();
                $element.find(selectors.classLinkedin).attr("href", modal.linkedin);
            }else{
                $element.find(selectors.classLinkedin).hide();
            }

            $element.addClass(selectors.classOpen);
        });
    }

    function SpeakerModal(config) {
        function init(config) {
            const $element = $(config.element);
            
            $(document).on('click', selectors.showSpeakerDetails, function () {
                var path = $(this).data('path');
                $(selectors.body).addClass(selectors.shadow);
                showSpeakerDetails(path, $element);
            });

            $(selectors.close).on('click', function () {
                $element.removeClass(selectors.classOpen);
                $(selectors.body).removeClass(selectors.shadow);
            });

            window.addEventListener('click', function(e) {
                if (e.path && !e.path.some(x => x.className && x.className.includes(selectors.modalContent))) {
                    $element.removeClass(selectors.classOpen);
                    $(selectors.body).removeClass(selectors.shadow);
                }
            }, true)     
            
        }
        if (config && config.element) {
            init(config);
        }
    }

    function onDocumentReady() {
        var element = document.querySelector(selectors.self);
        new SpeakerModal({ element: element });
    }
    if (document.readyState !== 'loading') {
        onDocumentReady();
    } else {
        document.addEventListener('DOMContentLoaded', onDocumentReady);
    }
})();
